import { createContext, useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getMergeStatement,
  getMergeStatementPartnerLogList,
  getMergeStatementFineTuningLogList,
  getMergeStatementList,
  getStatementList,
  getStatementChart,
  postMergeStatementStartTuning,
  postMergeStatementExtendTuning,
  postMergeStatementMarkAsError,
  postMergeStatementMarkAsGood,
  postMergeStatementMerge,
  postMergeStatementSendEmail,
  getStatementPageList,
  getStatement,
  getStatementPage,
  postStatementPage,
  putStatementSavePage,
  deleteStatementPage,
  getStatementTransactionList,
  putStatementSaveTransaction,
  postStatementParse,
  postStatementProcess,
  getStatementDS,
  putStatementSaveDS,
  getStatementDSFraudList,
  putStatementSaveDSFraud,
  postMergeStatementCallbackPartner,
  getParserStatementModuleNameList,
  getPartnerList,
  getPartnerCompanyList,
} from "utils/api";
import {
  transformSnakeToCamelInArray,
  transformSnakeToCamelObject,
  transformChartToMultiDatasets,
  chartColors,
  getBoSettingsValue,
  updateBoSettings,
} from "utils/helper";
import { useNotification } from "contexts/notification";
import { useSocket } from "contexts/socket";

const paginationListDefault = {
  data: [],
  meta: {
    totalPage: 0,
    totalData: 0,
    totalDataPerPage: 0,
    currentPage: 0,
  },
};

const simpleListDefault = {
  data: [],
  meta: {
    totalData: 0,
  },
  default: true,
};

const chartDefault = {
  labels: [],
  datasets: [],
};

const responseDefault = {
  type: null,
  status: false,
  message: null,
  data: null,
};

const EXECUTION_INTERVAL_TIME = 3 * 1000;
const RESET_NEW_UUIDS_DEBOUNCE_TIME = 10 * 1000;
const RESET_NEW_ROWSTYLE_DEBOUNCE_TIME = 30 * 1000;

export const StatementContext = createContext(null);

export const useStatement = () => {
  const ctx = useContext(StatementContext);

  if (!ctx) {
    throw new Error("useStatement must be used within the StatementProvider");
  }

  return ctx;
};

const StatementProvider = ({ children }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pushNotification } = useNotification();
  const { subscribe, unsubscribe, events } = useSocket();

  const autoUpdateSettings = useRef({ uuids: [] });
  const [isAutoUpdateList, setIsAutoUpdateList] = useState(getBoSettingsValue("mergeStatementAutoUpdate") || false);
  const [mergeStatementList, setMergeStatementList] = useState(paginationListDefault);
  const [mergeStatementListParams, setMergeStatementListParams] = useState({});
  const [statementList, setStatementList] = useState(simpleListDefault);
  const [statementDailyChart, setStatementDailyChart] = useState(chartDefault);
  const [statementMonthlyChart, setStatementMonthlyChart] = useState(chartDefault);
  const [statementYearlyChart, setStatementYearlyChart] = useState(chartDefault);
  const [statementMonthlyPartnerCharts, setStatementMonthlyPartnerCharts] = useState([]);
  const [statementYearlyPartnerCharts, setStatementYearlyPartnerCharts] = useState([]);
  const [statementMonthlyBankCharts, setStatementMonthlyBankCharts] = useState([]);
  const [statementYearlyBankCharts, setStatementYearlyBankCharts] = useState([]);
  const [statementPageList, setStatementPageList] = useState(paginationListDefault);
  const [statementTransactionList, setStatementTransactionList] = useState(simpleListDefault);
  const [mergeStatement, setMergeStatement] = useState(null);
  const [mergeStatementPartnerLogList, setMergeStatementPartnerLogList] = useState(simpleListDefault);
  const [mergeStatementFineTuningLogList, setMergeStatementFineTuningLogList] = useState(simpleListDefault);
  const [statement, setStatement] = useState(null);
  const [statementPage, setStatementPage] = useState(null);
  const [statementDS, setStatementDS] = useState(null);
  const [statementDSFraudList, setStatementDSFraudList] = useState(simpleListDefault);
  const [response, setResponse] = useState(responseDefault);
  const [loading, setLoading] = useState(false);
  const [chartLoading, setChartLoading] = useState(false);
  const [parserModuleNameList, setParserModuleNameList] = useState(simpleListDefault);
  const [statementModuleNameList, setStatementModuleNameList] = useState(simpleListDefault);
  const [partnerList, setPartnerList] = useState(simpleListDefault);
  const [partnerCompanyList, setPartnerCompanyList] = useState(simpleListDefault);

  const handleAutoUpdateList = (event) => {
    updateBoSettings("mergeStatementAutoUpdate", event.target.checked);
    setIsAutoUpdateList(event.target.checked);
  };

  const handleGetMergeStatementList = async (params) => {
    const currentSettingsIsAutoUpdateList = autoUpdateSettings.current.isAutoUpdateList;
    const currentSettingsUuids = [...(autoUpdateSettings.current.uuids || [])];

    // Cancels resetting new uuids.
    if (autoUpdateSettings.current.resetNewUuidsTimeoutId) {
      clearTimeout(autoUpdateSettings.current.resetNewUuidsTimeoutId);
      autoUpdateSettings.current.resetNewUuidsTimeoutId = null;
    }

    // Resets new uuids.
    autoUpdateSettings.current.resetNewUuidsTimeoutId = setTimeout(() => {
      autoUpdateSettings.current.uuids = [];
    }, RESET_NEW_UUIDS_DEBOUNCE_TIME);

    setMergeStatementListParams(params);

    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "MERGE_STATEMENT_LIST",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getMergeStatementList(params);

      if (res.status === 200) {
        const resetRowStyleMergeStatementList = () => {
          setMergeStatementList((prev) => ({
            ...prev,
            data: prev.data.map((x) => {
              const item = { ...x };
              delete item.rowStyle;
              return item;
            }),
            meta: {
              ...prev.meta,
              totalPage: res.data.meta.total_page,
              totalData: res.data.meta.total_data_all,
              totalDataPerPage: res.data.meta.total_data || params.limit,
              currentPage: params.page,
            },
          }));
        };
        const setupRowStyleMergeStatementList = (formattedList1, newUuids) => {
          setMergeStatementList((prev) => {
            const oldList = prev.data || [];
            const newItems = formattedList1.filter((newItem) => {
              if (newUuids.includes(newItem.uuid)) return true;

              const foundItem = oldList.find((oldItem) => oldItem.uuid === newItem.uuid);
              if (!foundItem) return false;
              if (newUuids.includes(foundItem.uuid)) return true;
              return false;
            });
            newItems.forEach((newItem) => (newItem.rowStyle = "table-success"));
            const newList = {
              ...prev,
              data: formattedList1,
              meta: {
                ...prev.meta,
                totalPage: res.data.meta.total_page,
                totalData: res.data.meta.total_data_all,
                totalDataPerPage: res.data.meta.total_data || params.limit,
                currentPage: params.page,
              },
            };
            return newList;
          });
        };

        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        if (currentSettingsIsAutoUpdateList) {
          // Sets new item flags.
          setupRowStyleMergeStatementList(transformSnakeToCamelInArray(res.data.data), currentSettingsUuids);

          // Cancels resetting row style.
          if (autoUpdateSettings.current.resetNewRowStyleTimeoutId) {
            clearTimeout(autoUpdateSettings.current.resetNewRowStyleTimeoutId);
            autoUpdateSettings.current.resetNewRowStyleTimeoutId = null;
          }

          // Resets row style.
          autoUpdateSettings.current.resetNewRowStyleTimeoutId = setTimeout(
            resetRowStyleMergeStatementList,
            RESET_NEW_ROWSTYLE_DEBOUNCE_TIME,
          );
        } else {
          // Sets default list without newItem flags.
          setMergeStatementList((prev) => ({
            ...prev,
            data: formattedList,
            meta: {
              ...prev.meta,
              totalPage: res.data.meta.total_page,
              totalData: res.data.meta.total_data_all,
              totalDataPerPage: res.data.meta.total_data || params.limit,
              currentPage: params.page,
            },
          }));
        }

        setResponse((prev) => ({
          ...prev,
          data: formattedList,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetParserStatementModuleNameList = async (params = { search: "", type: "" }) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: `${params.type.toUpperCase()}_MODULE_NAME_LIST`,
    }));

    // api call
    try {
      const res = await getParserStatementModuleNameList(params);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        if (params.type === "parser") {
          setParserModuleNameList((prev) => ({
            ...prev,
            data: formattedList,
            meta: {
              ...prev.meta,
            },
          }));
        } else {
          setStatementModuleNameList((prev) => ({
            ...prev,
            data: formattedList,
            meta: {
              ...prev.meta,
            },
          }));
        }

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetPartnerList = async (params = { search: "", limit: "", uuid: "" }) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "PARTNER_LIST",
    }));

    // api call
    try {
      const res = await getPartnerList(params);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setPartnerList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev.meta,
          },
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetPartnerCompanyList = async (params = { search: "", limit: "" }) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "PARTNER_COMPANY_LIST",
    }));

    // api call
    try {
      const res = await getPartnerCompanyList(params);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setPartnerCompanyList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev.meta,
          },
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetMergeStatement = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "MERGE_STATEMENT",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getMergeStatement(id);

      if (res.status === 200) {
        const temp = transformSnakeToCamelObject(res.data.data);

        setMergeStatement((prev) => ({
          ...prev,
          ...temp,
        }));

        setResponse((prev) => ({
          ...prev,
          data: temp,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetMergeStatementPartnerLogList = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "MERGE_STATEMENT_PARTNER_LOG_LIST",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getMergeStatementPartnerLogList(id);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setMergeStatementPartnerLogList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev?.meta,
            totalPage: res.data.meta.total_page,
            totalData: res.data.meta.total_data_all,
          },
          default: false,
        }));

        setResponse((prev) => ({
          ...prev,
          data: formattedList,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetMergeStatementFineTuningLogList = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "MERGE_STATEMENT_FINETUNING_LOG_LIST",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getMergeStatementFineTuningLogList(id);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setMergeStatementFineTuningLogList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev?.meta,
            totalPage: res.data.meta.total_page,
            totalData: res.data.meta.total_data_all,
          },
          default: false,
        }));

        setResponse((prev) => ({
          ...prev,
          data: formattedList,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetStatementList = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "MERGE_STATEMENT_LIST",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getStatementList({ merge_uuid: id });

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setStatementList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev.meta,
            totalData: res.data.meta.total_data,
            hitlLinkGuidance: res.data.meta.hitl_link_guidance,
          },
        }));

        setResponse((prev) => ({
          ...prev,
          data: formattedList,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetStatementChart = async (type) => {
    if (!type) return;

    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "STATEMENT_CHART_" + type.toUpperCase(),
    }));
    setChartLoading(true);

    // api call
    try {
      const res = await getStatementChart({ type });
      if (res.status === 200) {
        // set state
        let fn;
        if (type.endsWith("_partner")) {
          if (type === "monthly_partner") {
            fn = setStatementMonthlyPartnerCharts;
          } else if (type === "yearly_partner") {
            fn = setStatementYearlyPartnerCharts;
          }

          if (fn) {
            const datasets = transformChartToMultiDatasets(res.data.data, "partner_name", "processed_usage_count");
            fn(datasets);
          }
        } else if (type.endsWith("_bank")) {
          let fn;
          if (type === "monthly_bank") {
            fn = setStatementMonthlyBankCharts;
          } else if (type === "yearly_bank") {
            fn = setStatementYearlyBankCharts;
          }

          if (fn) {
            const datasets = transformChartToMultiDatasets(res.data.data, "bank_name", "processed_usage_count");
            fn(datasets);
          }
        } else {
          if (type === "daily") {
            fn = setStatementDailyChart;
          } else if (type === "monthly") {
            fn = setStatementMonthlyChart;
          } else if (type === "yearly") {
            fn = setStatementYearlyChart;
          }

          if (fn) {
            const labels = res.data.data.map((x) => x.period_label);
            const allCounts = res.data.data.map((x) => x.all_count);
            const processedNonHitlCounts = res.data.data.map((x) => x.processed_nonhitl_count);
            const processedHitlSuccessCounts = res.data.data.map((x) => x.processed_hitlsuccess_count);
            const processedHitlErrorCounts = res.data.data.map((x) => x.processed_hitlerror_count);
            const nonProcessedCounts = res.data.data.map((x) => x.non_processed_count);
            const processedUsageCounts = res.data.data.map((x) => x.processed_usage_count);

            fn((prev) => ({
              ...prev,
              labels: labels,
              datasets: [
                {
                  label: "UPLOADED",
                  data: allCounts,
                  backgroundColor: chartColors.blue,
                  stack: "all",
                },
                {
                  label: "PROCESSED NON HITL",
                  data: processedNonHitlCounts,
                  backgroundColor: chartColors.teal,
                  stack: "processed",
                },
                {
                  label: "PROCESSED HITL SUCCESS",
                  data: processedHitlSuccessCounts,
                  backgroundColor: chartColors.green,
                  stack: "processed",
                },
                {
                  label: "PROCESSED HITL ERROR",
                  data: processedHitlErrorCounts,
                  backgroundColor: chartColors.red,
                  stack: "processed",
                },
                {
                  label: "NON PROCESSED",
                  data: nonProcessedCounts,
                  backgroundColor: chartColors.gray,
                  stack: "processed",
                },
                {
                  label: "PROCESSED USAGE",
                  data: processedUsageCounts,
                  backgroundColor: chartColors.orange,
                  stack: "usage",
                },
              ],
            }));
          }
        }

        setResponse((prev) => ({
          ...prev,
          data: res.data.data,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setChartLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handlePostMergeStatementStartTuning = async (id, forceStartTuning = false) => {
    let isSuccess = false;
    let message = null;
    let errorCode = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "STATEMENT_START_TUNING",
    }));
    setLoading(true);

    // api call
    try {
      const res = await postMergeStatementStartTuning(id, { is_force_start_tuning: forceStartTuning });

      if (res.status === 200) {
        isSuccess = true;
        message = res.data.message;
        pushNotification("success", message);
      }
    } catch (e) {
      errorCode = e?.response?.data?.error_code;
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return {
      status: isSuccess,
      message,
      errorCode,
    };
  };

  const handlePostMergeStatementExtendTuning = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "STATEMENT_EXTEND_TUNING",
    }));
    setLoading(true);

    // api call
    try {
      const res = await postMergeStatementExtendTuning(id);

      if (res.status === 200) {
        isSuccess = true;
        message = res.data.message;
        pushNotification("success", message);
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handlePostMergeStatementMerge = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "STATEMENT_MERGE",
    }));
    setLoading(true);

    // api call
    try {
      const res = await postMergeStatementMerge(id);

      if (res.status === 200) {
        isSuccess = true;
        message = res.data.message;
        pushNotification("success", message);
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handlePostMergeStatementSendEmail = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "STATEMENT_SENDEMAIL",
    }));
    setLoading(true);

    // api call
    try {
      const res = await postMergeStatementSendEmail(id);

      if (res.status === 200) {
        isSuccess = true;
        message = res.data.message;
        pushNotification("success", message);
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handlePostMergeStatementCallbackPartner = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "STATEMENT_SENDCALLBACK",
    }));
    setLoading(true);

    // api call
    try {
      const res = await postMergeStatementCallbackPartner(id);

      if (res.status === 200) {
        isSuccess = true;
        message = res.data.message;
        pushNotification("success", message);
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handlePostMergeStatementMarkAsGood = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "STATEMENT_MARKASGOOD",
    }));
    setLoading(true);

    // api call
    try {
      const res = await postMergeStatementMarkAsGood(id);

      if (res.status === 200) {
        isSuccess = true;
        message = res.data.message;
        pushNotification("success", message);
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handlePostMergeStatementMarkAsError = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "STATEMENT_MARKASERROR",
    }));
    setLoading(true);

    // api call
    try {
      const res = await postMergeStatementMarkAsError(id);

      if (res.status === 200) {
        isSuccess = true;
        message = res.data.message;
        pushNotification("success", message);
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetStatement = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "STATEMENT",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getStatement(id);

      if (res.status === 200) {
        const temp = transformSnakeToCamelObject(res.data.data);

        setStatement((prev) => ({
          ...prev,
          ...temp,
        }));

        setResponse((prev) => ({
          ...prev,
          data: temp,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetStatementPageList = async (params, id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "STATEMENT_PAGE_LIST",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getStatementPageList(params, id);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setStatementPageList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev.meta,
            totalPage: res.data.meta.total_page,
            totalData: res.data.meta.total_data_all,
            totalDataPerPage: res.data.meta.total_data || params.limit,
            currentPage: params.page,
          },
        }));

        setResponse((prev) => ({
          ...prev,
          data: formattedList,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetStatementPage = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "STATEMENT_PAGE",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getStatementPage(id);

      if (res.status === 200) {
        const temp = transformSnakeToCamelObject(res.data.data);
        setStatementPage((prev) => ({
          ...prev,
          ...temp,
        }));

        setResponse((prev) => ({
          ...prev,
          data: temp,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handlePostStatementPage = async (id, payload) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "CREATE_STATEMENT_PAGE",
    }));
    setLoading(true);

    // api call
    let newId;
    try {
      const res = await postStatementPage(id, payload);

      if (res.status === 201) {
        isSuccess = true;
        message = res.data.message;
        newId = res?.data?.data?.uuid;
        pushNotification("success", message);
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return {
      success: isSuccess,
      newId,
    };
  };

  const handlePutStatementSavePage = async (id, payload) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "UPDATE_STATEMENT_PAGE",
    }));
    setLoading(true);

    // api call
    try {
      const res = await putStatementSavePage(id, payload);

      if (res.status === 200) {
        isSuccess = true;
        message = res.data.message;
        pushNotification("success", message);
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleDeleteStatementPage = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "DELETE_STATEMENT_PAGE",
    }));
    setLoading(true);

    // api call
    try {
      const res = await deleteStatementPage(id);

      if (res.status === 200) {
        isSuccess = true;
        message = res.data.message;
        pushNotification("success", message);
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetStatementTransactionList = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "STATEMENT_TRANSACTION_LIST",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getStatementTransactionList(id);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setStatementTransactionList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev.meta,
            totalData: res.data.meta.total_data,
          },
        }));

        setResponse((prev) => ({
          ...prev,
          data: formattedList,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handlePutStatementSaveTransaction = async (id, payload) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "UPDATE_STATEMENT_TRANSACTION",
    }));
    setLoading(true);

    // api call
    try {
      const res = await putStatementSaveTransaction(id, payload);

      if (res.status === 200) {
        isSuccess = true;
        message = res.data.message;
        pushNotification("success", message);
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handlePostStatementParse = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "STATEMENT_PARSE",
    }));
    setLoading(true);

    // api call
    try {
      const res = await postStatementParse(id);

      if (res.status === 200) {
        isSuccess = true;
        message = res.data.message;
        pushNotification("success", message);
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handlePostStatementProcess = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "STATEMENT_PROCESS",
    }));
    setLoading(true);

    // api call
    try {
      const res = await postStatementProcess(id);

      if (res.status === 200) {
        isSuccess = true;
        message = res.data.message;
        pushNotification("success", message);
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetStatementDS = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "STATEMENT_DS",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getStatementDS(id);

      if (res.status === 200) {
        const temp = transformSnakeToCamelObject(res.data.data);
        setStatementDS((prev) => ({
          ...prev,
          ...temp,
        }));

        setResponse((prev) => ({
          ...prev,
          data: temp,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handlePutStatementSaveDS = async (id, payload) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "UPDATE_STATEMENT_DS",
    }));
    setLoading(true);

    // api call
    try {
      const res = await putStatementSaveDS(id, payload);

      if (res.status === 200) {
        isSuccess = true;
        message = res.data.message;
        pushNotification("success", message);
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetStatementDSFraudList = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "STATEMENT_DS_FRAUD_LIST",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getStatementDSFraudList(id);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setStatementDSFraudList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev.meta,
            totalData: res.data.meta.total_data,
          },
        }));

        setResponse((prev) => ({
          ...prev,
          data: formattedList,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handlePutStatementSaveDSFraud = async (id, payload) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "UPDATE_STATEMENT_DS_FRAUD",
    }));
    setLoading(true);

    // api call
    try {
      const res = await putStatementSaveDSFraud(id, payload);

      if (res.status === 200) {
        isSuccess = true;
        message = res.data.message;
        pushNotification("success", message);
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  useEffect(() => {
    // Not sure needed or not to have local variables.
    const currentSearchParams = searchParams;
    const currentIsAutoUpdateList = isAutoUpdateList;
    const currentMergeStatementListParams = mergeStatementListParams;
    const currentLocationPathname = location.pathname;

    const notificationNewSubmissionHandler = async (data) => {
      if (!currentIsAutoUpdateList) return;
      if (data?.doc_type !== "bs") return;
      if (!currentLocationPathname?.startsWith("/statement-dashboard")) return;

      // Sets ref.
      if (!autoUpdateSettings.current.uuids) autoUpdateSettings.current.uuids = [];
      if (data?.uuid && !autoUpdateSettings.current.uuids.includes(data.uuid)) {
        autoUpdateSettings.current.uuids.push(data.uuid);
      }

      autoUpdateSettings.current.isAutoUpdateList = isAutoUpdateList;

      const execute = async (qp, lp) => {
        autoUpdateSettings.current.executionTimeoutId = null;

        // Redirects to page 1.
        const queryParams = new URLSearchParams(qp);
        const page = queryParams.get("page");
        if (page && page !== "1") {
          queryParams.set("page", 1);
          navigate(`?${queryParams.toString()}`);
          return;
        }

        // Directly calls the same params.
        await handleGetMergeStatementList(lp);
      };

      if (!autoUpdateSettings.current.executionTimeoutId) {
        // Starts a delay execution.
        const qp = currentSearchParams;
        const lp = currentMergeStatementListParams;
        autoUpdateSettings.current.executionTimeoutId = setTimeout(execute, EXECUTION_INTERVAL_TIME, qp, lp);
      }
    };

    subscribe(events.NOTIFICATION_NEW_SUBMISSION_EVENT, notificationNewSubmissionHandler);
    return () => {
      unsubscribe(events.NOTIFICATION_NEW_SUBMISSION_EVENT, notificationNewSubmissionHandler);
    };
  }, [searchParams, isAutoUpdateList, mergeStatementListParams, location.pathname]);

  useEffect(() => {
    return () => {
      if (autoUpdateSettings.current.executionTimeoutId) {
        clearTimeout(autoUpdateSettings.current.executionTimeoutId);
        autoUpdateSettings.current.executionTimeoutId = null;
      }
    };
  }, []);

  return (
    <StatementContext.Provider
      value={{
        isAutoUpdateList,
        mergeStatementList,
        statementDailyChart,
        statementMonthlyChart,
        statementYearlyChart,
        statementMonthlyPartnerCharts,
        statementYearlyPartnerCharts,
        statementMonthlyBankCharts,
        statementYearlyBankCharts,
        statementList,
        statementPageList,
        statementTransactionList,
        mergeStatement,
        mergeStatementPartnerLogList,
        mergeStatementFineTuningLogList,
        statement,
        statementPage,
        statementDS,
        statementDSFraudList,
        loading,
        chartLoading,
        response,
        parserModuleNameList: parserModuleNameList,
        statementModuleNameList: statementModuleNameList,
        partnerList: partnerList,
        partnerCompanyList: partnerCompanyList,
        handleAutoUpdateList,
        getMergeStatementList: handleGetMergeStatementList,
        getMergeStatement: handleGetMergeStatement,
        getMergeStatementPartnerLogList: handleGetMergeStatementPartnerLogList,
        getMergeStatementFineTuningLogList: handleGetMergeStatementFineTuningLogList,
        getStatementChart: handleGetStatementChart,
        getStatementList: handleGetStatementList,
        postMergeStatementStartTuning: handlePostMergeStatementStartTuning,
        postMergeStatementExtendTuning: handlePostMergeStatementExtendTuning,
        postMergeStatementMerge: handlePostMergeStatementMerge,
        postMergeStatementMarkAsGood: handlePostMergeStatementMarkAsGood,
        postMergeStatementMarkAsError: handlePostMergeStatementMarkAsError,
        postMergeStatementSendEmail: handlePostMergeStatementSendEmail,
        postMergeStatementCallbackPartner: handlePostMergeStatementCallbackPartner,
        getStatement: handleGetStatement,
        getStatementPageList: handleGetStatementPageList,
        getStatementPage: handleGetStatementPage,
        putStatementSavePage: handlePutStatementSavePage,
        deleteStatementPage: handleDeleteStatementPage,
        postStatementPage: handlePostStatementPage,
        getStatementTransactionList: handleGetStatementTransactionList,
        putStatementSaveTransaction: handlePutStatementSaveTransaction,
        postStatementParse: handlePostStatementParse,
        postStatementProcess: handlePostStatementProcess,
        getStatementDS: handleGetStatementDS,
        putStatementSaveDS: handlePutStatementSaveDS,
        getStatementDSFraudList: handleGetStatementDSFraudList,
        putStatementSaveDSFraud: handlePutStatementSaveDSFraud,
        getParserStatementModuleNameList: handleGetParserStatementModuleNameList,
        getPartnerList: handleGetPartnerList,
        getPartnerCompanyList: handleGetPartnerCompanyList,
      }}
    >
      {children}
    </StatementContext.Provider>
  );
};

export default StatementProvider;
