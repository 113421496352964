import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSlik } from "contexts/slik";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const tableHeader = (
  <tr>
    <th style={{ width: "1%", minWidth: "30px" }}>#</th>
    <th>Partner</th>
    <th>File Name</th>
    <th style={{ width: "120px" }}>Processed At</th>
    <th style={{ width: "110px" }}>Created At</th>
    <th style={{ width: "7.5%" }}>Action</th>
  </tr>
);

const DashboardTable = () => {
  const { mergeSlikList, loading } = useSlik();

  const startDataCount = (mergeSlikList.meta.currentPage - 1) * mergeSlikList.meta.totalDataPerPage + 1;

  return (
    <Table responsive bordered striped hover size="sm" className="mb-4">
      <thead>{tableHeader}</thead>
      <tbody>
        {loading
          ? Array.from(Array(5).keys()).map((val1) => (
              <tr key={val1}>
                {Array.from(Array(8).keys()).map((val2) => (
                  <td key={`${val1}${val2}`}>
                    <Skeleton />
                  </td>
                ))}
              </tr>
            ))
          : mergeSlikList.data.map((item, index) => (
              <tr key={item.uuid} className={`${item.rowStyle || ""}`}>
                <td className="text-end pe-2">{startDataCount + index}</td>
                <td>{item.partnerName}</td>
                <td>{item.fileName}</td>
                <td>{item.processedAt}</td>
                <td>{item.createdAt}</td>
                <td>
                  <OverlayTrigger overlay={<Tooltip>Open</Tooltip>}>
                    <Button variant="link" className="px-1" as={Link} to={`../merge-slik/${item.uuid}`}>
                      <FontAwesomeIcon icon={faEye} className="text-primary pointer-event" />
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
      </tbody>
    </Table>
  );
};

export default DashboardTable;
