import { useAuth } from "contexts/auth";
import { useCallback, useEffect } from "react";
import { Outlet, Navigate, useNavigate, useLocation } from "react-router-dom";
import { getCookie } from "utils/cookie";

const ProtectedRoute = ({ children }) => {
  const { verify } = useAuth();
  const isAuth = getCookie();
  const navigate = useNavigate();
  const location = useLocation();

  // check auth from server
  const verifyAuth = useCallback(async (pathName) => {
    // INFO called twice in dev bca of React.StrictMode
    const payload = {
      path: pathName,
    };
    const res = await verify(payload);

    if (!res) {
      if (pathName !== "/login") {
        navigate("/login?redirect=" + encodeURIComponent(pathName));
      } else {
        navigate("/login");
      }
    }
  }, []);

  useEffect(() => {
    verifyAuth(location.pathname);
  }, [location.pathname]);

  if (!isAuth) return <Navigate to="login" replace />;

  return children || <Outlet />;
};

export default ProtectedRoute;
