import { Container } from "react-bootstrap";
import "./index.css";
import AdminTracker from "components/Common/AdminTracker";

const Footer = () => (
  <>
    <div className="footer py-2 fixed-bottom">
      <Container className="text-center">
        <p>© {new Date().getFullYear()} Fineksi</p>
      </Container>
    </div>
    <div className="fixed-bottom footer-width-admin-tracker">
      <AdminTracker />
    </div>
  </>
);

export default Footer;
