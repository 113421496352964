import MergePefindoDashboardTable from "components/Pefindo/MergePefindoDashboardTable";
import MergePefindoDashboardTableInfo from "components/Pefindo/MergePefindoDashboardTableInfo";

const Dashboard = ({ onPageChange, params }) => {
  return (
    <>
      <MergePefindoDashboardTableInfo onPageChange={onPageChange} limit={params.limit} />
      <MergePefindoDashboardTable />
      <MergePefindoDashboardTableInfo onPageChange={onPageChange} limit={params.limit} />
    </>
  );
};

export default Dashboard;
