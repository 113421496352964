import { useAuth } from "contexts/auth";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const AdminTracker = () => {
  const navigate = useNavigate();

  const { user, currentAdmins: admins } = useAuth();
  return (
    <div className="d-flex flex-column ms-2 mb-5">
      {admins.map((admin, index) => {
        const splits = admin.name.split(" ").map((word) => word[0]);
        const initials = (splits[0] || "") + (splits[splits.length - 1] || "");

        let textColor;
        if (admin.here) {
          textColor = "text-black";
        } else {
          textColor = "text-danger";
        }

        let bgColor;
        let opacity;
        let border;
        let popoverStatus;
        if (admin.activityStatus === "online") {
          bgColor = "bg-success-subtle";
          opacity = "opacity-100";
          border = "border border-3 border-success";
          popoverStatus = "fw-bold text-success";
        } else if (admin.activityStatus === "recently") {
          bgColor = "bg-success-subtle";
          opacity = "opacity-100";
          border = "";
          popoverStatus = "text-success";
        } else if (admin.activityStatus === "away") {
          bgColor = "bg-secondary-subtle";
          opacity = "opacity-100";
          border = "";
          popoverStatus = "";
        } else {
          bgColor = "bg-secondary-subtle";
          opacity = "opacity-50";
          border = "";
          popoverStatus = "";
        }

        const divClass = [
          textColor,
          "d-flex",
          "justify-content-center",
          "align-items-center",
          "rounded-circle",
          "m-1",
          bgColor,
          opacity,
          border,
        ].join(" ");
        const showPointer = !admin.here && user.uuid !== admin.uuid;
        const divStyle = {
          width: "30px",
          height: "30px",
          fontSize: 12,
          fontWeight: "bold",
          cursor: showPointer ? "pointer" : "default",
        };

        const handleClick = () => {
          if (admin.lastPath) {
            navigate(admin.lastPath); // Navigate to the path
          }
        };

        const popover = (
          <Popover id={`popover-${admin.uuid}`} style={{ minWidth: "450px" }}>
            <Popover.Header>
              {admin.name}
              {admin.here ? " is here" : " is somewhere else"}
            </Popover.Header>
            <Popover.Body>
              <div className="row">
                <div className="col-5 fw-bold">Last Activity At</div>
                <div className="col-7">: {admin.lastActivityAt}</div>
              </div>
              <div className="row">
                <div className="col-5 fw-bold">Last Activity Ago</div>
                <div className="col-7">: {admin.lastActivityAgo}</div>
              </div>
              <div className="row">
                <div className="col-5 fw-bold">Status</div>
                <div className={`col-7 ${popoverStatus}`}>: {admin.activityStatus}</div>
              </div>
              <div className="mt-2">
                <div>{admin.lastPath}</div>
              </div>
              {showPointer && <p className="mt-2 text-primary">Click the circle to follow</p>}
            </Popover.Body>
          </Popover>
        );

        return (
          <OverlayTrigger key={index} placement="right" overlay={popover}>
            <div key={index} className={divClass} style={divStyle} onClick={handleClick}>
              {initials}
            </div>
          </OverlayTrigger>
        );
      })}
    </div>
  );
};

export default AdminTracker;
