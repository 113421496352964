import { createContext, useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import Cookies from "js-cookie";

import { useAuth } from "contexts/auth";
import { useNotification } from "contexts/notification";

export const SocketContext = createContext(null);

const TRACKER_NEW_ADMIN_EVENT = "tracker-new-admin";
const NOTIFICATION_NEW_SUBMISSION_EVENT = "notification-new-submission";

const audio = new Audio(
  "https://s3.ap-southeast-3.amazonaws.com/internal.asset.fineksi.com/audios/notifications/minus.mp3",
);

const ucWords = (str) => {
  if (!str) return str;
  const words = str.trim().split(/\s+/);
  const capitalizedWords = words.map((word) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase());
  const newStr = capitalizedWords.join(" ").trim();
  return newStr;
};

export const useSocket = () => {
  const ctx = useContext(SocketContext);

  if (!ctx) {
    throw new Error("useSocket must be used within the SocketProvider");
  }

  return ctx;
};

const SocketProvider = ({ children }) => {
  const { pushNotification } = useNotification();
  const location = useLocation();
  const { handleUnshiftNewAdmin } = useAuth();
  const socket = useRef(null);
  const events = useRef({});

  // Subscribes to an event.
  const subscribe = (eventName, callback) => {
    if (!events.current[eventName]) {
      events.current[eventName] = [];
    }
    events.current[eventName].push(callback);
  };

  // Unsubscribes from an event.
  const unsubscribe = (eventName, callback) => {
    if (events.current[eventName]) {
      events.current[eventName] = events.current[eventName].filter((cb) => cb !== callback);
    }
  };

  // Publishes an event.
  const publish = (eventName, data) => {
    if (events.current[eventName]) {
      events.current[eventName].forEach((callback) => callback(data));
    }
  };

  // Socket handler.
  const trackerNewAdminHandler = async (data) => {
    try {
      // Propagates.
      publish(TRACKER_NEW_ADMIN_EVENT, data);

      if (!data?.newAdmin) return;

      await handleUnshiftNewAdmin({
        ...data.newAdmin,
        here: location.pathname === data.newAdmin.last_path,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Socket handler.
  const notificationNewSubmissionHandler = async (data) => {
    try {
      // Propagates.
      publish(NOTIFICATION_NEW_SUBMISSION_EVENT, data);

      if (data?.action_type === "uploading") {
        pushNotification(
          "success",
          `New ${ucWords(data?.action_type)} of ${data?.doc_type?.toUpperCase()} / ${data?.partner?.company_name}`,
        );
        return;
      }

      pushNotification(
        "success",
        `New ${ucWords(data?.action_type)} of ${data?.doc_type?.toUpperCase()} / ${data?.partner?.company_name}`,
      );
      if (process.env.REACT_APP_MODE_ENV === "local" || process.env.REACT_APP_MODE_ENV === "production") {
        // audio.load();
        await audio.play();
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    try {
      // Validates.
      const token = Cookies.get("token-bo");
      if (!token) return;

      // Inits socket.
      socket.current = io(process.env.REACT_APP_BO_SOCKET_URL, {
        transports: ["websocket"],
        auth: { token },
      });

      socket.current.on(TRACKER_NEW_ADMIN_EVENT, trackerNewAdminHandler);
      socket.current.on(NOTIFICATION_NEW_SUBMISSION_EVENT, notificationNewSubmissionHandler);
      // eslint-disable-next-line no-empty
    } catch (err) {
      console.log(err);
    }

    // Clean up socket connection on unmount
    return () => {
      try {
        if (!socket.current) return;

        // console.log(socket.current.connected);
        socket.current.off(TRACKER_NEW_ADMIN_EVENT, trackerNewAdminHandler);
        socket.current.off(NOTIFICATION_NEW_SUBMISSION_EVENT, notificationNewSubmissionHandler);
        socket.current.disconnect();
      } catch (err) {
        console.log(err);
      }
    };
  }, [location.pathname]);

  return (
    <SocketContext.Provider
      value={{
        subscribe,
        unsubscribe,
        events: {
          TRACKER_NEW_ADMIN_EVENT,
          NOTIFICATION_NEW_SUBMISSION_EVENT,
        },
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
